<template>
  <div id="home">
    <core-jumbotron-mobile />
    <v-row>
      <v-col
        class="d-flex child-flex pa-0"
        md="4"
        cols="6"
      >
        <v-hover
          v-slot="{ hover }"
        >
          <v-card
            tile
            class="mx-auto text-center "
            :to="{ name: 'products', params: {lang:$i18n.locale },query:{category:'soil-preparation'}}"
          >
            <v-img
              src="/static/product-section-1---Soil-Prep.jpg"
              aspect-ratio="1"
              class="grey lighten-2 white--text align-end"
              :gradient="hover ? 'to top, rgba(0,0,0,1), rgba(237,243,244,0) 80%' : 'to top, rgba(0,0,0,1), rgba(237,243,244,0) 20%'"
            >
              <v-btn
                outlined
                small
                dark
                :large="hover"
                class="mb-5"
              >
                {{ $t("home.soil_preparation") }}
              </v-btn>
              <template #placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  />
                </v-row>
              </template>
            </v-img>
          </v-card>
        </v-hover>
      </v-col>

      <v-col
        class="d-flex child-flex pa-0"
        md="4"
        cols="6"
      >
        <v-hover
          v-slot="{ hover }"
        >
          <v-card
            class="mx-auto text-center "
            tile
            elevation="1"
            ripple
            :to="{ name: 'products', params: {lang:$i18n.locale },query:{category:'planting-and-seeding'}}"
          >
            <v-img
              src="/static/product-section-2---Seeding-and-Planting.jpg"
              aspect-ratio="1"
              class="grey lighten-2 white--text align-end"
              :gradient="hover ? 'to top, rgba(0,0,0,1), rgba(237,243,244,0) 80%' : 'to top, rgba(0,0,0,1), rgba(237,243,244,0) 20%'"
            >
              <v-btn
                outlined
                small
                dark
                :large="hover"
                class="mb-5"
              >
                {{ $t("home.planting_seeding") }}
              </v-btn>
              <template #placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  />
                </v-row>
              </template>
            </v-img>
          </v-card>
        </v-hover>
      </v-col>

      <v-col
        class="d-flex child-flex pa-0"
        md="4"
        cols="6"
      >
        <v-hover
          v-slot="{ hover }"
        >
          <v-card
            class="mx-auto text-center "
            tile
            :to="{ name: 'products', params: {lang:$i18n.locale },query:{category:'crop-care'}}"
          >
            <v-img
              src="/static/product-section-3---spraying-and-spreading.jpg"
              aspect-ratio="1"
              class="grey lighten-2 white--text align-end"
              :gradient="hover ? 'to top, rgba(0,0,0,1), rgba(237,243,244,0) 80%' : 'to top, rgba(0,0,0,1), rgba(237,243,244,0) 20%'"
            >
              <v-btn
                outlined
                small
                dark
                :large="hover"
                class="mb-5"
              >
                {{ $t("home.crop_care") }}
              </v-btn>

              <template #placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  />
                </v-row>
              </template>
            </v-img>
          </v-card>
        </v-hover>
      </v-col>
      <v-col
        class="d-flex child-flex pa-0"
        md="4"
        cols="6"
      >
        <v-hover
          v-slot="{ hover }"
        >
          <v-card
            tile
            class="mx-auto text-center "
            :to="{ name: 'products', params: {lang:$i18n.locale },query:{category:'harvest'}}"
          >
            <v-img
              src="/static/product-section-4---harvest.jpg"
              aspect-ratio="1"
              class="grey lighten-2 white--text align-end"
              :gradient="hover ? 'to top, rgba(0,0,0,1), rgba(237,243,244,0) 80%' : 'to top, rgba(0,0,0,1), rgba(237,243,244,0) 20%'"
            >
              <v-btn
                outlined
                small
                dark
                :large="hover"
                class="mb-5"
              >
                {{ $t("home.harvest") }}
              </v-btn>

              <template #placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  />
                </v-row>
              </template>
            </v-img>
          </v-card>
        </v-hover>
      </v-col>
      <v-col
        class="d-flex child-flex pa-0"
        md="4"
        cols="6"
      >
        <v-hover
          v-slot="{ hover }"
        >
          <v-card
            tile
            class="mx-auto text-center "
            :to="{ name: 'products', params: {lang:$i18n.locale },query:{category:'vineyard'}}"
          >
            <v-img
              src="/static/product-section-5---vineyard.jpg"
              aspect-ratio="1"
              class="grey lighten-2 white--text align-end"
              :gradient="hover ? 'to top, rgba(0,0,0,1), rgba(237,243,244,0) 80%' : 'to top, rgba(0,0,0,1), rgba(237,243,244,0) 20%'"
            >
              <v-btn
                outlined
                small
                dark
                :large="hover"
                class="mb-5"
              >
                {{ $t("home.vineyard") }}
              </v-btn>

              <template #placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  />
                </v-row>
              </template>
            </v-img>
          </v-card>
        </v-hover>
      </v-col>

      <v-col
        class="d-flex child-flex pa-0"
        md="4"
        cols="6"
      >
        <v-hover
          v-slot="{ hover }"
        >
          <v-card
            tile
            class="mx-auto text-center "

            :to="{ name: 'products', params: {lang:$i18n.locale },query:{category:'other'}}"
          >
            <v-img
              src="/static/product-category6--- wheat field.jpg"
              aspect-ratio="1"
              class="grey lighten-2 white--text align-end"
              :gradient="hover ? 'to top, rgba(0,0,0,1), rgba(237,243,244,0) 80%' : 'to top, rgba(0,0,0,1), rgba(237,243,244,0) 20%'"
            >
              <v-btn
                outlined
                small
                dark
                :large="hover"
                class="mb-5"
              >
                {{ $t("home.other") }}
              </v-btn>

              <template #placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  />
                </v-row>
              </template>
            </v-img>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>

    <v-row class="fill-height">
      <v-img
        class="pa-0 ma-0 "
        gradient="to top, rgba(237,243,244,1), rgba(237,243,244,0) 20%"

        position="top"
        :src="(!isMobile()?'/static/home.jpg':'/static/home-mobile.jpg')"
      >
        <v-row
          style="background-color: rgba(255,255,255,0.8);margin-top:0%"
          align="center"
          justify="center"
        >
          <v-col
            v-if="promotions_load"
            cols="10"
          >
            <h2
              class="text-center"
            >
              {{ $t("home.special_offers") }}
            </h2>
            <carousel3d
              v-if="promotions_load"
              :display="(isMobile()?1:(promotions.length<3?promotions.length:3))"
              :controls-visible="promotions.length>1"
              :autoplay="true"
              :autoplay-timeout="10000"
              :autoplay-hover-pause="true"
              :perspective="(isMobile()?0:(promotions.length===1?0:70))"
              :width="!isMobile()?windowWidth*0.5:windowWidth"
              :height="isMobile()?390:400"
              style="background:none"
            >
              <slide
                v-for="(slide,i) in promotions"
                :key="i"
                :index="i"
                style="border:none;background:none"
              >
                <v-card
                  xml:lang="en"
                  :data-index="index"

                  :class="{ current: isCurrent, onLeft: (leftIndex >= 0), onRight: (rightIndex >= 0) }"
                >
                  <v-img
                    :src="slide.product.image"
                    class="white--text align-end"
                    gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,.8)"
                    :height="isMobile()?'270px':'340px'"
                  >
                    <v-card-title
                      style="white-space:break-spaces !important;word-break: keep-all"
                      class="
                      heading
                      text-bold
                      text-h4
                      "
                      v-text="slide.promotion.content[$i18n.locale].title"
                    />

                    <v-card-subtitle
                      class="title white--text"
                      v-text="slide.promotion.content[$i18n.locale].sub_title"
                    />
                  </v-img>

                  <v-card-actions
                    class="pl-1 pr-5 pt-0 pb-0"
                    style="height: 70px"
                  >
                    <v-spacer />
                    <v-card-text v-text="slide.product.name" />

                    <v-btn
                      v-if="isMobile()"
                      fab
                      small
                      color="primary"
                      :to="{ name: 'product', params: {lang:$i18n.locale, company: slide.product.company,product:slide.product.id }}"
                    >
                      <v-icon>
                        mdi-magnify
                      </v-icon>
                    </v-btn>

                    <v-btn
                      v-if="!isMobile()"
                      color="primary"
                      :to="{ name: 'product', params: {lang:$i18n.locale, company: slide.product.company,product:slide.product.id }}"
                    >
                      {{ $t("home.find_out_more") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </slide>
            </carousel3d>
          </v-col>
        </v-row>
        <v-row
          style="background-color: rgba(255,255,255,0.8);margin-top:20%"
          align="center"
          justify="center"
        >
          <v-col

            class=" subtitle-1 blue-grey--text"
            cols="10"
            sm="8"
            md="4"
            lg="5"
            offset-sm="0"
            offset-md="0"
            offset="1"
          >
            <div
              class="mb-5"
              v-html="$t('home.farmers')"
            />
            <div
              class="mb-5"
              v-html="$t('home.dealers')"
            />
            <div
              class="mb-5"
              v-html="$t('home.manufacturers')"
            />
          </v-col>
          <v-col
            cols="1"
            sm="3"
            md="2"
          >
            <v-img

              class="pa-0 ma-0 "

              src="/static/oria@symbol.png"
            />
          </v-col>
        </v-row>
      </v-img>
    </v-row>
  </div>
</template>

<script>
  import i18n from '../i18n'
  import moment from 'moment'
  import { Carousel3d, Slide } from 'vue-carousel-3d'
  export default {
    metaInfo: {
      title: i18n.t('menu.home'),
      meta: [
        { name: 'description', content: 'Oria Agriculture' },
      ],
    },
    components: {
      carousel3d: Carousel3d,
      slide: Slide,
      CoreJumbotronMobile: () => import('@/components/core/JumbotronMobile'),
    },
    data: () => ({
      promotions: [],
      promotions_load: false,
      num_promotions: 0,
      windowWidth: window.innerWidth,
    }),
    mounted () {
      this.$nextTick(() => {
        window.addEventListener('resize', () => {
          this.windowWidth = window.innerWidth
        })
      })
    },
    beforeCreate () {
      const promotionsRef = this.$firebaseDatabase.collection('promotions')
      const _this = this

      promotionsRef.where('to', '>', moment().unix()).get().then(function (querySnapshot) {
        const _len = querySnapshot.size
        querySnapshot.docs.forEach(snapshot => {
          if (snapshot.data().from < moment().unix()) {
            const _data = snapshot.data()

            fetch('/api/' + i18n.locale + '/product/' + _data.product + '.json')
              .then(r => r.json())
              .then(json => {
                      const parts = _data.product.split('/')
                      _this.promotions.push({
                        promotion: _data,
                        product: {
                          image: json.image,
                          name: json.name,
                          company: parts[0],
                          id: parts[1],
                        },
                      })
                      if (_this.promotions.length === _len) {
                        _this.promotions_load = true
                      }
                    },
                    response => {
                      console.log('Error loading json:', response)
                    })
          }
        })
      })
    },

    methods: {

      isMobile () {
        if (this.windowWidth <= 960) {
          return true
        } else {
          return false
        }
      },
    },

  }

</script>
<style lang="sass">
@import '~vuetify/src/styles/styles.sass'
#projects
  .v-slide-group__content
    justify-content: center
</style>
